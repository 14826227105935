import React from 'react';
import { Route } from 'react-router-dom';
import LoadingPage from '../Components/LoadingPage';

// Lazy-loaded components
const LazyLandingPage = React.lazy(() => import('../Containers/LandingPageContainer'));
const LazyMyLoanContainer = React.lazy(() => import('../Containers/MyLoanContainer'));
const LazyCommonForms = React.lazy(() => import('../Containers/CommonFormsContainer'));
const LazyCommonQuestions = React.lazy(() => import('../Components/CommonQuestions'));
const LazyContact = React.lazy(() => import('../Components/Contact'));
const LazyPrivacyPolicy = React.lazy(() => import('../Components/PrivacyPolicy'));
const LazyTermsConditions = React.lazy(() => import('../Components/TermsAndConditions'));
const LazyUpload = React.lazy(() => import('../Components/Upload/index'));
const LazyScheduleOfFees = React.lazy(() => import('../Components/ScheduleOfFees'));
const LazyLicenses = React.lazy(() => import('../Components/Licenses'));
const LazyAccessibilityStatement = React.lazy(() => import('../Components/AccessibilityStatement'));
const LazyAdvanceRequestContainer = React.lazy(() => import('../Containers/AdvanceRequestContainer'));
const LazyMyAdvanceRequestsContainer = React.lazy(() => import('../Containers/MyAdvanceRequestsContainer'));
const LazyPayoffQuote = React.lazy(() => import('../Containers/PayoffQuoteContainer'));
const LazyImportantDisclosures = React.lazy(() => import('../Components/ImportantDisclosures'));
const LazyAccountSettings = React.lazy(() => import('../Containers/AccountSettings'));
const LazyFinishYourAccountContainer = React.lazy(() => import('../Containers/FinishYourAccountContainer'));
const LazyTransactions = React.lazy(() => import('../Components/Transactions/index'));
const LazyStatementsAndDocuments = React.lazy(() => import('../Components/StatementsAndDocuments/index'));
const LazyAnnualPrivacyNotice = React.lazy(() => import('../Components/AnnualPrivacyNotice/index'));
const LazyClaim = React.lazy(() => import('../Components/Claim'));
const LazyESignContainer = React.lazy(() => import('../Containers/ESignContainer'));
const LazyHelpfulResourcesContainer = React.lazy(() => import('../Containers/HelpfulResourcesContainer'));
const LazyEnableMultifactorContainer = React.lazy(() => import('../Containers/EnableMultifactorContainer'));
const LazyPaymentContainer = React.lazy(() => import('../Containers/PaymentContainer'));
const LazyBadRequestPage = React.lazy(() => import('../Components/BadRequestPage') )
// Example routes array
export const RouteList = [
  { path: '/', component: LazyLandingPage },
  { path: '/paperless', component: LazyLandingPage },
  { path: '/advance-request', component: LazyAdvanceRequestContainer },
  { path: '/transactions', component: LazyTransactions },
  { path: '/statements-and-documents', component: LazyStatementsAndDocuments },
  { path: '/annual-privacy-notice', component: LazyAnnualPrivacyNotice },
  { path: '/my-advance-requests', component: LazyMyAdvanceRequestsContainer },
  { path: '/payoff-quote', component: LazyPayoffQuote },
  { path: '/my-loan', component: LazyMyLoanContainer },
  { path: '/my-loan/register', component: LazyLandingPage },
  { path: '/my-loan/register', component: LazyBadRequestPage },
  { path: '/esign', component: LazyESignContainer },
  { path: '/enable-multifactor', component: LazyEnableMultifactorContainer },
  { path: '/helpful-resources', component: LazyHelpfulResourcesContainer },
  { path: '/common-questions', component: LazyCommonQuestions },
  { path: '/contact', component: LazyContact },
  { path: '/common-forms', component: LazyCommonForms },
  { path: '/account-settings', component: LazyAccountSettings },
  { path: '/finish-your-account', component: LazyFinishYourAccountContainer },
  { path: '/upload', component: LazyUpload },
  { path: '/privacy-policy', component: LazyPrivacyPolicy },
  { path: '/terms-conditions', component: LazyTermsConditions },
  { path: '/schedule-of-borrower-fees', component: LazyScheduleOfFees },
  { path: '/licenses', component: LazyLicenses },
  { path: '/important-disclosures', component: LazyImportantDisclosures },
  { path: '/claims', component: LazyClaim },
  { path: '/accessibility-statement', component: LazyAccessibilityStatement },
  { path: '/payment', component: LazyPaymentContainer },
];

// Function to render routes dynamically
export const Routes = () => (
  <>
    {RouteList.map(({ path, component: Component }) => (
      <Route key={path} exact path={path} render={(props) => (
        <React.Suspense fallback={<div> <LoadingPage /> </div>}>
          <Component {...props} />
        </React.Suspense>
      )} />
    ))}
  </>
);
